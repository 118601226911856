import TodoIcon from "./assets/icons/To-do.svg";
import AddIcon from "./assets/icons/add.svg";
import MenuIcon from "./assets/icons/3 dot menu.svg";
import DisplayIcon from "./assets/icons/Display.svg";
import DownIcon from "./assets/icons/down.svg";
import NoPriorityIcon from "./assets/icons/No-priority.svg";
import LowPriorityIcon from "./assets/icons/Img - Low Priority.svg";
import MediumPriorityIcon from "./assets/icons/Img - Medium Priority.svg";
import HighPriorityIcon from "./assets/icons/Img - High Priority.svg";
import UrgentPriorityIcon from "./assets/icons/SVG - Urgent Priority grey.svg";
import UrgentPriorityColorIcon from "./assets/icons/SVG - Urgent Priority colour.svg";
import BacklogIcon from "./assets/icons/Backlog.svg";
import InProgressIcon from "./assets/icons/in-progress.svg";
import DoneIcon from "./assets/icons/Done.svg";
import CancelledIcon from "./assets/icons/Cancelled.svg";

export const icons = {
  todo: TodoIcon,
  backlog: BacklogIcon,
  "in progress": InProgressIcon,
  done: DoneIcon,
  cancelled: CancelledIcon,
  add: AddIcon,
  menu: MenuIcon,
  display: DisplayIcon,
  downArrow: DownIcon,
  priorityOrdered: [
    NoPriorityIcon,
    LowPriorityIcon,
    MediumPriorityIcon,
    HighPriorityIcon,
    UrgentPriorityIcon,
  ],
  "no priority": NoPriorityIcon,
  "low priority": LowPriorityIcon,
  "medium priority": MediumPriorityIcon,
  "high priority": HighPriorityIcon,
  "urgent priority": UrgentPriorityColorIcon,
};

export const priorityMap = {
  "no priority": 0,
  "low priority": 1,
  "medium priority": 2,
  "high priority": 3,
  "urgent priority": 4,
};

export const ApiEndPoints = {
  getData: "https://api.quicksell.co/v1/internal/frontend-assignment",
};
