import { icons } from "../../constants";

import { Card } from "../../components";
import { ProfileChip } from "../profile-chip/profile-chip";

export const Column = ({ title, tickets, grouping, data }) => {
  return (
    <div className="column">
      <div className="column__header">
        <div className="column__info">
          {grouping === "users" ? (
            <ProfileChip
              name={title}
              isAvailable={
                data.users.find(
                  (user) => user.name.toLowerCase() === title.toLowerCase()
                ).available ?? false
              }
            />
          ) : (
            <div className="column__icon-wrapper">
              <img src={icons[title]} alt="column-icon" />
            </div>
          )}
          <h3 className="column__title">{title ?? "Todo"}</h3>
          <span className="column__task-count">{tickets.length}</span>
        </div>

        <div className="column__action-wrapper">
          <button className="column__action-button">
            <img src={icons.add} alt="Add task" />
          </button>

          <button className="column__action-button">
            <img src={icons.menu} alt="Menu" />
          </button>
        </div>
      </div>

      <div className="column__task-list">
        {tickets &&
          tickets.map((ticket) => (
            <Card key={ticket.id} grouping={grouping} {...ticket} data={data} />
          ))}
      </div>
    </div>
  );
};
